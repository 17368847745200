export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },

  components: {
    hotPaymentMethodsItem: () => import('~/components/Molecules/PaymentMethodsItem/PaymentMethodsItem.vue'),
  },
}
